<template>
  <BlokkliProvider v-slot="{ entity }" v-bind="blokkliProps" :entity="props">
    <BlokkliField :list="paragraphs" name="field_paragraphs" />
  </BlokkliProvider>
  
  <section v-if="showLocationSearch">
    <p>Location Search</p>
  </section>
</template>

<script lang="ts" setup>
import type { NodePageFragment } from '#graphql-operations'

const props = defineProps<{
  uuid: string
  title?: string
  lead?: string
  paragraphs?: NodePageFragment['paragraphs']
  body?: string
  blokkliProps: NodePageFragment['blokkliProps']
  showLocationSearch?: boolean
}>()

</script>
